<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="wellhole">
      <el-tab-pane label="电梯查询" name="first">
        <ElevatorInquire></ElevatorInquire>
      </el-tab-pane>
      <el-tab-pane label="井道报告" name="second">
        <WellholeReport></WellholeReport>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ElevatorInquire from "./ElevatorInquire.vue";
import WellholeReport from "./WellholeReport.vue";
export default {
  data() {
    return {
      activeName: "",
    };
  },
  props: {},
  components: { ElevatorInquire, WellholeReport },
  methods: {
    handleClick() {},
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
:deep .el-tabs__nav {
  width: 100%;
}
:deep .wellhole {
  .el-tabs__item {
    width: 50%;
    text-align: center;
    font-weight: bold;
  }
}
</style>