<template>
  <div>
    <div class="content">
      <div class="left">
        <div style="padding: 0px 10px">
          <el-button
              type="primary"
              style="margin-bottom: 10px; width: 100%"
              @click="back"
          >返回
          </el-button
          >
        </div>
        <div class="taskMenu">
          <div
              :class="menuKey == index ? 'menuItem menuActive' : 'menuItem'"
              v-for="(item, index) in TaskMenu"
              :key="item.name"
              @click="menuTaskChange(item, index)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div class="right">
        <div v-if="menuKey == 1">
          <CalculatePowerList
              @view="isview = true"
              v-if="!isview"
          ></CalculatePowerList>
          <CalculatePowerInfo
              v-if="isview"
              @back="isview = false"
          ></CalculatePowerInfo>
        </div>
        <div v-if="menuKey == 0">
          <WellholeInquireList
              @view="isview2 = true"
              v-if="!isview2"
          ></WellholeInquireList>
          <WellholeInquireInfo
              v-if="isview2"
              @back="isview2 = false"
          ></WellholeInquireInfo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WellholeInquireList from "./WellholeInquireList";
import CalculatePowerList from "./CalculatePowerList";
import WellholeInquireInfo from "./WellholeInquireInfo.vue";
import CalculatePowerInfo from "./CalculatePowerInfo.vue";

export default {
  data() {
    return {
      TaskMenu: [
        {name: "电梯速查GO", label: "电梯速查GO"},
        // { name: "算运力", label: "算运力" },

      ],
      menuKey: 0,
      activeName: "",
      isview: false,
      isview2: false,
    };
  },
  props: {},
  components: {
    WellholeInquireList,
    CalculatePowerList,
    WellholeInquireInfo,
    CalculatePowerInfo,
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    menuTaskChange(e, index) {
      console.log(index);
      if (index === 0) {
        this.isview = false;
      } else {
        this.isview2 = false;
      }

      if (this.menuKey !== index) {
      }
      this.menuKey = index;
    },
    handleClick() {
    },
  },
  mounted() {
  },
  created() {
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.content {
  background: #f7f7f7;

  width: 100%;
  display: flex;

  .left {
    width: 200px;
    height: 100vh;
    padding: 20px 0px;
    border-right: 1px solid #ccc;
    box-sizing: border-box;
  }

  .right {
    padding: 20px;
    box-sizing: border-box;
    width: calc(100% - 210px);
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
  }
}

.taskMenu {
  border-right: 1px solid #ededed;
  text-align: center;

  box-sizing: border-box;
  padding: 0 10px;
  position: relative;
  transition: all 70ms ease 0s;

  .menuItem {
    font-size: 14px;
    padding: 7px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    // height: 80px;
    line-height: 80px;
    cursor: pointer;
    white-space: nowrap;
    border: 3px solid #fae7e7;
    font-weight: bold;

    &:hover {
      background: #fae7e7;
      color: #d1100b;
      font-weight: 600;
    }
  }

  .menuActive {
    background: #fae7e7;
    color: #d1100b;
    font-weight: 600;
  }

  .shrink {
    position: absolute;
    bottom: 10px;
    right: -12px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;

    &:hover {
      color: #d1100b;
      border-color: #d1100b;
    }
  }
}
</style>