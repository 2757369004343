<template>
  <div>
    <el-form
      ref="form"
      :model="formInfo"
      label-width="150px"
      size="small"
      style="padding: 10px"
      :rules="rules"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="项目名称" prop="a">
            <el-input
              v-model="formInfo.a"
              placeholder="输入项目名称"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目城市" prop="b">
            <el-select v-model="formInfo.b" placeholder="请选择">
              <el-option label="北京" value="1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="建筑类型" prop="c">
            <el-radio-group v-model="formInfo.c">
              <el-radio label="多层" value="0"></el-radio>
              <el-radio label="高层" value="1"></el-radio>
              <el-radio label="超高层" value="2"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="总建筑面积(㎡)" prop="d">
            <el-input
              v-model="formInfo.d"
              placeholder="输入总建筑面积"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="标准层建筑面积(㎡)" prop="e">
            <el-input
              v-model="formInfo.e"
              placeholder="输入标准层建筑面积"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="建筑总高度(m)" prop="f">
            <el-input
              v-model="formInfo.f"
              placeholder="输入建筑总高度"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="标准层层高(m)" prop="g">
            <el-input
              v-model="formInfo.g"
              placeholder="输入标准层层高"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="建筑分区信息" prop="h">
            <el-radio-group v-model="formInfo.h">
              <el-radio label="0">分区</el-radio>
              <el-radio label="1">不分区</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 分区弹窗 -->
      <el-row class="content" v-if="formInfo.h === '0'">
        <el-row class="content-item">
          <div style="text-align: center; font-weight: bold">
            <span style="color: red">*</span>地上层数(含本区避难层或设备夹层)
          </div>
        </el-row>
        <el-row :gutter="20" class="content-item">
          <el-col :span="8">
            <el-form-item label="高区楼层" label-width="80px">
              <el-input
                v-model="formInfo.hh.a"
                placeholder="输入"
                style="width: 80px"
              />至<el-input
                v-model="formInfo.hh.b"
                style="width: 80px"
                placeholder="输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="中区楼层" label-width="80px">
              <el-input
                v-model="formInfo.hh.a"
                placeholder="输入"
                style="width: 80px"
              />至<el-input
                v-model="formInfo.hh.b"
                placeholder="输入"
                style="width: 80px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="低区楼层" label-width="80px">
              <el-input
                v-model="formInfo.hh.a"
                style="width: 80px"
                placeholder="输入"
              />至<el-input
                v-model="formInfo.hh.b"
                style="width: 80px"
                placeholder="输入"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="bottom-bor"></div>
        </el-row>
        <el-row :gutter="20" class="content-item">
          <el-col :span="8">
            <el-form-item label="地上层数" label-width="80px">
              <el-input v-model="formInfo.hh.c" placeholder="输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="地下层数" label-width="80px">
              <el-input v-model="formInfo.hh.d" placeholder="输入" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="bottom-bor"></div>
        </el-row>
        <el-row class="content-item">
          <div style="text-align: center; font-weight: bold">
            <span style="color: red">*</span>避难层层高
          </div>
        </el-row>
        <el-row :gutter="20" class="content-item">
          <el-col :span="8">
            <el-form-item label="高区(m)" label-width="80px">
              <el-input v-model="formInfo.hh.c" placeholder="输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="中区(m)" label-width="80px">
              <el-input v-model="formInfo.hh.d" placeholder="输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="低区(m)" label-width="80px">
              <el-input v-model="formInfo.hh.d" placeholder="输入" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
      <!-- ↑ -->
      <el-row>
        <el-col :span="12">
          <el-form-item label="添加业态" prop="i">
            <el-radio-group v-model="formInfo.i">
              <el-radio label="0" value="0">酒店</el-radio>
              <el-radio label="1" value="1">办公</el-radio>
              <el-radio label="2" value="2">公寓</el-radio>
              <el-radio label="3" value="3">商业</el-radio>
              <el-radio label="4" value="4">综合体</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 业态分类 -->
      <el-row class="item1" v-if="formInfo.i === '0'">
        <div
          style="
            font-weight: bold;
            color: red;
            text-align: center;
            margin-bottom: 18px;
          "
        >
          业态:酒店
        </div>
        <el-row>
          <el-form-item label="酒店品牌" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="万豪" value="0"></el-radio>
              <el-radio label="希尔顿" value="1"></el-radio>
              <el-radio label="凯悦" value="2"></el-radio>
              <el-radio label="洲际" value="3"></el-radio>
              <el-radio label="温德姆" value="4"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他品牌"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="星级" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="五星级" value="0"></el-radio>
              <el-radio label="四星级" value="1"></el-radio>
              <el-radio label="三星级" value="2"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他品牌"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="酒店类型" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="度假型" value="0"></el-radio>
              <el-radio label="商务型" value="1"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他品牌"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="客房数量" required>
            <el-input
              v-model="formInfo.g"
              style="width: 150px"
              placeholder="输入客房数量"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-row>
      <el-row class="item1" v-if="formInfo.i === '1'">
        <div
          style="
            font-weight: bold;
            color: red;
            text-align: center;
            margin-bottom: 18px;
          "
        >
          业态:办公
        </div>
        <el-row>
          <el-form-item label="商圈" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="大望路" value="0"></el-radio>
              <el-radio label="小马厂" value="1"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="最高楼层" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="5" value="0"></el-radio>
              <el-radio label="10" value="1"></el-radio>
              <el-radio label="15" value="2"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他品牌"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-row>
      <el-row class="item1" v-if="formInfo.i === '2'">
        <div
          style="
            font-weight: bold;
            color: red;
            text-align: center;
            margin-bottom: 18px;
          "
        >
          业态:公寓
        </div>
        <el-row>
          <el-form-item label="级别" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="一级" value="0"></el-radio>
              <el-radio label="二级" value="1"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="楼型" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="A楼型" value="0"></el-radio>
              <el-radio label="B楼型" value="1"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-row>
      <el-row class="item1" v-if="formInfo.i === '3'">
        <div
          style="
            font-weight: bold;
            color: red;
            text-align: center;
            margin-bottom: 18px;
          "
        >
          业态:商业
        </div>
        <el-row>
          <el-form-item label="商圈" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="西单" value="0"></el-radio>
              <el-radio label="公主坟" value="1"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="物业费" required>
            <el-radio-group v-model="formInfo.i">
              <el-radio label="10/平米/天" value="0"></el-radio>
              <el-radio label="15/平米/天" value="1"></el-radio>
            </el-radio-group>
            <el-input
              style="width: 150px; margin-left: 100px"
              v-model="formInfo.g"
              placeholder="可在此输入其他"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-row>
      <!-- 综合业态 -->
      <el-row
        class="content content-yt"
        v-if="formInfo.i === '4'"
        :style="{ height: contentStyle.height }"
      >
        <el-row class="content-item">
          <el-col :span="16" style="text-align: center">
            <el-radio-group v-model="formInfo.l">
              <el-radio-button label="0">酒店</el-radio-button>
              <el-radio-button label="1">办公</el-radio-button>
              <el-radio-button label="2">公寓</el-radio-button>
              <el-radio-button label="3">商业</el-radio-button>
            </el-radio-group>
          </el-col>
          <el-col :span="8" style="text-align: right">
            <el-button type="primary" @click="addYt">+添加业态</el-button>
          </el-col>
        </el-row>
        <el-row class="content-item">
          <div class="bottom-bor"></div>
        </el-row>
        <div v-for="(item, index) in ytList" :key="item.id">
          <el-row v-if="item.id === '0'">
            <div class="itemTitle">
              <div>业态:酒店</div>
              <div class="itemBut">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click="deletdYt(index)"
                  >删除业态</el-button
                >
              </div>
            </div>
            <el-row>
              <el-form-item label="酒店品牌" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="万豪" value="0"></el-radio>
                  <el-radio label="希尔顿" value="1"></el-radio>
                  <el-radio label="凯悦" value="2"></el-radio>
                  <el-radio label="洲际" value="3"></el-radio>
                  <el-radio label="温德姆" value="4"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="formInfo.g"
                  placeholder="可在此输入其他品牌"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="星级" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="五星级" value="0"></el-radio>
                  <el-radio label="四星级" value="1"></el-radio>
                  <el-radio label="三星级" value="2"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="formInfo.g"
                  placeholder="可在此输入其他品牌"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="酒店类型" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="度假型" value="0"></el-radio>
                  <el-radio label="商务型" value="1"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="formInfo.g"
                  placeholder="可在此输入其他品牌"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="客房数量" required>
                <el-input
                  v-model="item.g"
                  style="width: 150px"
                  placeholder="输入客房数量"
                ></el-input>
              </el-form-item>
            </el-row>
            <div class="bottom-bor"></div>
          </el-row>
          <el-row v-if="item.id === '1'">
            <div class="itemTitle">
              <div>业态:办公</div>
              <div class="itemBut">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click="deletdYt(index)"
                  >删除业态</el-button
                >
              </div>
            </div>
            <el-row>
              <el-form-item label="商圈" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="大望路" value="0"></el-radio>
                  <el-radio label="小马厂" value="1"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="item.g"
                  placeholder="可在此输入其他"
                ></el-input>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item label="最高楼层" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="5" value="0"></el-radio>
                  <el-radio label="10" value="1"></el-radio>
                  <el-radio label="15" value="2"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="item.g"
                  placeholder="可在此输入其他品牌"
                ></el-input>
              </el-form-item>
            </el-row>
            <div class="bottom-bor"></div>
          </el-row>
          <el-row v-if="item.id === '2'">
            <div class="itemTitle">
              <div>业态:公寓</div>
              <div class="itemBut">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click="deletdYt(index)"
                  >删除业态</el-button
                >
              </div>
            </div>
            <el-row>
              <el-form-item label="级别" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="一级" value="0"></el-radio>
                  <el-radio label="二级" value="1"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="item.g"
                  placeholder="可在此输入其他"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="楼型" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="A楼型" value="0"></el-radio>
                  <el-radio label="B楼型" value="1"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="item.g"
                  placeholder="可在此输入其他"
                ></el-input>
              </el-form-item>
            </el-row>
            <div class="bottom-bor"></div>
          </el-row>
          <el-row v-if="item.id === '3'">
            <div class="itemTitle">
              <div>业态:商业</div>
              <div class="itemBut">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click="deletdYt(index)"
                  >删除业态</el-button
                >
              </div>
            </div>
            <el-row>
              <el-form-item label="商圈" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="西单" value="0"></el-radio>
                  <el-radio label="公主坟" value="1"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="item.g"
                  placeholder="可在此输入其他"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="物业费" required>
                <el-radio-group v-model="item.i">
                  <el-radio label="10/平米/天" value="0"></el-radio>
                  <el-radio label="15/平米/天" value="1"></el-radio>
                </el-radio-group>
                <el-input
                  style="width: 150px; margin-left: 100px"
                  v-model="item.g"
                  placeholder="可在此输入其他"
                ></el-input>
              </el-form-item>
            </el-row>
            <div class="bottom-bor"></div
          ></el-row>
        </div>
        <div
          class="iconbut"
          @click="changeContentYt"
          v-if="contentStyle.height != 'auto' && ytList.length > 1"
        >
          <i class="el-icon-arrow-down"></i>
        </div>
        <div
          class="iconbut"
          @click="changeContentYt"
          v-if="contentStyle.height == 'auto'"
        >
          <i class="el-icon-arrow-up"></i>
        </div>
      </el-row>
      <!--  -->
      <el-row>
        <el-col>
          <el-form-item label="上传文件" prop="j">
            <div style="color: #a5a5a5">
              注:图纸要求:平面图、剖面图、立面图，需反应如下信息;1:各层层高2:各层建筑面积/功能
            </div>
            <div>
              <span style="color: red"
                >文件不得超过000M，过大请发送至邮箱:xxxxxxx.163.com</span
              >
              <el-input
                v-model="formInfo.j"
                placeholder="请输入您的发件邮箱"
                style="width: 200px; margin-left: 20px"
              ></el-input>
              <upload url="123" :is_disabled="false"></upload>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="留言框">
          <el-input
            v-model="formInfo.k"
            placeholder="输入留言信息，非必填"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <div class="bottom-bor"></div>
      </el-row>
      <el-row style="height: 100px; line-height: 100px">
        <el-col :span="12" style="color: #a5a5a5; font-size: 13px">
          小提示:报告生成后，可在“查井道项目列表”查看
        </el-col>

        <el-col :span="12" style="text-align: right">
          <el-button type="primary" @click="submit">提交申请</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import upload from "@/components/upload.vue";
export default {
  data() {
    return {
      formInfo: {
        hh: {
          a: "",
          b: "",
          c: "",
          d: "",
          e: "",
          f: "",
        },
      },
      rules: {
        a: [{ required: true }],
        b: [{ required: true }],
        c: [{ required: true }],
        d: [{ required: true }],
        e: [{ required: true }],
        f: [{ required: true }],
        g: [{ required: true }],
        h: [{ required: true }],
        i: [{ required: true }],
        j: [{ required: true }],
        k: [{ required: true }],
      },
      ytList: [],
      contentStyle: {
        height: "500px",
      },
    };
  },
  props: {},
  components: { upload },
  methods: {
    addYt() {
      if (this.formInfo.l) {
        let flag = this.ytList.find((item) => {
          return item.id === this.formInfo.l;
        });
        if (flag) {
          this.$message.warning("已添加过当前业态");
          return;
        }
        this.ytList.push({
          id: this.formInfo.l,
          i: "0",
        });
      } else {
        this.$message.warning("请选择业态后添加");
      }
    },
    changeContentYt() {
      if (this.contentStyle.height == "auto") {
        this.contentStyle.height = "500px";
      } else {
        this.contentStyle.height = "auto";
      }
    },
    deletdYt(index) {
      this.ytList.splice(index, 1);
    },
    submit() {
      this.$emit("submit", 0);
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.bottom-bor {
  width: 100%;
  border-bottom: 1px dashed #ccc;
}
.content {
  padding: 0 5%;
  border: 8px solid #ccc;
  margin-bottom: 18px;
}
.content-item {
  padding-top: 18px;
}
.content-yt {
  padding: 18px 5%;
  overflow: hidden;
  position: relative;
}
.item1 {
  padding: 0 5%;
  border: 8px solid #ccc;
}
.itemTitle {
  width: 100%;
  font-weight: bold;
  color: red;
  text-align: center;

  line-height: 46px;
}
.itemBut {
  position: absolute;
  right: 0;
  top: 0;
}
.iconbut {
  width: 40px;
  background-color: #ccc;
  position: absolute;
  right: 50%;
  bottom: 0;
  text-align: center;
  margin-left: -20px;
  cursor: pointer;
}
</style>