<template>
  <div style="padding: 0 30px">
    <div class="hint">
      查井道·井道报告
      <span>提示:先将“电梯报告”加入清单后，才能选择生成并道报告!</span>
    </div>
    <div class="content">
      <div v-for="item in 10" :key="item">
        <el-row :gutter="30" class="item">
          <el-col :span="18">
            <el-row class="title"> 北京市朝阳去万豪酒店项目 </el-row>
            <el-row class="content-item">
              北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目北京市朝阳去万豪酒店项目
            </el-row>
            <el-row class="time"> 创建时间：2020-01-01 </el-row>
          </el-col>
          <el-col :span="6" style="text-align: right">
            <el-button type="text" icon="el-icon-share">分享</el-button>
          </el-col>
        </el-row>
        <div class="bottom-bor"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  props: {},
  components: {},
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.hint {
  font-weight: bold;
  span {
    color: red;
  }
}
.item {
  padding: 30px;
  display: flex;
  align-items: center;
  .title {
    font-weight: bold;
    margin: 10px 0;
  }
  .content-item {
    color: #c6c6c6;
    font-size: 14px;
    margin: 10px 0;
  }
  .time {
    color: #c6c6c6;
    font-size: 14px;
    margin: 10px 0;
  }
}
.bottom-bor {
  width: 100%;
  border-bottom: 1px dashed #ccc;
}
</style>