<template>
  <div>
    <el-dialog :visible.sync="Visible" width="50%" center>
      <div slot="title">
        <div style="font-weight: bold">添加电梯</div>
      </div>
      <div style="text-align: right; width: 100%">
        <el-button @click="reset" icon="el-icon-refresh" type="text"
          >重置</el-button
        >
      </div>
      <el-form
        ref="form"
        :model="formInfo"
        label-width="150px"
        size="small"
        style="padding: 10px"
        :rules="rules"
      >
        <el-form-item label="综合体业态" prop="a" required v-if="ytflag">
          <el-radio-group v-model="formInfo.a">
            <el-radio label="0" value="0">酒店</el-radio>
            <el-radio label="1" value="1">办公</el-radio>
            <el-radio label="2" value="2">公寓</el-radio>
            <el-radio label="3" value="3">商业</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="电梯类型" prop="b" required>
          <el-radio-group v-model="formInfo.b">
            <el-radio label="0" value="0">客梯</el-radio>
            <el-radio label="1" value="1">货梯</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="电梯编号" prop="c" required>
          <el-input v-model="formInfo.c" placeholder="输入电梯编号"></el-input>
        </el-form-item>
        <el-form-item label="载重" prop="d" required>
          <el-radio-group v-model="formInfo.d" class="radio">
            <el-radio v-for="item in list" :key="item" :label="item">{{
              item
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="梯速" prop="e" required>
          <el-radio-group v-model="formInfo.e" class="radio">
            <el-radio v-for="item in list2" :key="item" :label="item">{{
              item
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="行程高度(m)" prop="f" required>
          <el-input v-model="formInfo.f" placeholder="输入行程高度"></el-input>
        </el-form-item>
        <el-form-item label="机房类型" prop="g" required>
          <el-radio-group v-model="formInfo.g">
            <el-radio label="0" value="0">标准机房</el-radio>
            <el-radio label="1" value="1">无机房</el-radio>
            <el-radio label="2" value="2">小机房</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="轿厢类型" prop="h" required>
          <el-radio-group v-model="formInfo.h">
            <el-radio label="0" value="0">宽轿厢</el-radio>
            <el-radio label="1" value="1">深轿厢</el-radio>
            <el-radio label="2" value="2">贯通门</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="开门方式" prop="i" required>
          <el-radio-group v-model="formInfo.i">
            <el-radio label="0" value="0">中分</el-radio>
            <el-radio label="1" value="1">旁开</el-radio>
            <el-radio label="2" value="2">中分四扇</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="对重类型" prop="j" required>
          <el-radio-group v-model="formInfo.j">
            <el-radio label="0" value="0">后置式</el-radio>
            <el-radio label="1" value="1">前置式</el-radio>
            <el-radio label="2" value="2">测置式</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="最大停战数" prop="k" required>
          <el-radio-group v-model="formInfo.k">
            <el-radio label="0" value="0">7</el-radio>
            <el-radio label="1" value="1">8</el-radio>
            <el-radio label="2" value="2">9</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Visible: false,
      formInfo: {
        index: 1,
        a: "",
        b: "",
        c: "",
        d: "",
        e: "",
        f: "",
        g: "",
        h: "",
        i: "",
        j: "",
        k: "",
        l: "",
        m: "",
        n: "",
        collapse: true,
      },
      rules: {},
      list: [
        "550kg",
        "650kg",
        "750kg",
        "850kg",
        "950kg",
        "1050kg",
        "110kg",
        "120kg",
        "130kg",
        "140kg",
        "150kg",
        "160kg",
        "170kg",
        "180kg",
        "190kg",
        "200kg",
        "210kg",
        "220kg",
      ],
      list2: [
        "1m/s",
        "1.6m/s",
        "1.75m/s",
        "2m/s",
        "2.5m/s",
        "3m/s",
        "3.5m/s",
        "4/s",
        "5m/s",
        "6m/s",
        "7m/s",
        "8m/s",
        "9m/s",
        "10m/s",
      ],
      ytflag: false,
    };
  },
  props: {},
  components: {},
  methods: {
    init(isRedact, yt) {
      if (yt === "4") {
        this.ytflag = true;
      } else {
        this.ytflag = false;
      }
      this.Visible = true;
    },
    reset() {
      this.formInfo = {
        collapse: true,
      };
    },
    submit() {
      this.Visible = false;
      this.formInfo.index = this.formInfo.index + 1;

      this.$emit("submit", this.formInfo);
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
:deep .radio .el-radio {
  margin-bottom: 10px;
}
</style>