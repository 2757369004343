<template>
  <div>
    <el-row class="header">
      <div class="title">算运力</div>
      <el-button
        type="primary"
        class="header-button"
        size="mini"
        v-if="isTrue"
        @click="isTrue = false"
        >新建</el-button
      >
      <el-button
        type="primary"
        class="header-button"
        size="mini"
        v-if="!isTrue"
        @click="isTrue = true"
        >返回</el-button
      >
    </el-row>
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      class="tab"
      v-if="isTrue"
    >
      <el-tab-pane label="未提交" name="0" class="tabitem">
        <div class="content">
          <el-row class="but-title">
            <el-col :span="12">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </el-col>
            <el-col :span="12" style="text-align: right">
              <el-button type="primary">提交订单</el-button>
            </el-col>
          </el-row>
          <!-- <el-checkbox v-for="city in cities" :label="city" :key="city"></el-checkbox> -->

          <div
            v-for="item in notFiledList"
            :key="item.id"
            @click.stop="viewInfo"
          >
            <div class="border-top"></div>
            <div class="item">
              <div class="item-checkbox">
                <el-checkbox :label="item.istrue"> </el-checkbox>
              </div>
              <div class="text">
                <div>{{ item.title }}</div>
                <div class="time">创建时间:{{ item.time }}</div>
              </div>
              <div class="but1">
                <el-button
                  type="text"
                  class="button1"
                  icon="el-icon-copy-document"
                  >拷贝新订单</el-button
                >
              </div>
              <div class="but2">
                <el-button type="text" class="button1" icon="el-icon-crop"
                  >编辑</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="计算中" name="1" class="tabitem">
        <div
          v-for="(item, index) in calculateList"
          :key="item.id"
          @click.stop="viewInfo"
        >
          <div class="border-top" v-if="index != 0"></div>
          <div class="item">
            <div class="calculate-text">
              <div>
                {{ item.title }}
                <el-tag size="mini" type="warning">计算中</el-tag>
              </div>
              <div class="time">创建时间:{{ item.time }}</div>
            </div>
            <div class="but1">
              <el-button
                type="text"
                class="button1"
                icon="el-icon-copy-document"
                >拷贝新订单</el-button
              >
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="2" class="tabitem"
        ><div
          v-for="(item, index) in finishedList"
          :key="item.id"
          @click.stop="viewInfo"
        >
          <div class="border-top" v-if="index != 0"></div>
          <div class="item">
            <div class="calculate-finished">
              <div>
                {{ item.title }}
                <el-tag size="mini" type="warning">已完成</el-tag>
              </div>
              <div class="time">创建时间:{{ item.time }}</div>
            </div>
            <div class="but1">
              <el-button
                type="text"
                class="button1"
                icon="el-icon-copy-document"
                >拷贝新订单</el-button
              >
            </div>
            <div class="but2">
              <el-button
                type="text"
                class="button1"
                icon="el-icon-copy-document"
                >下载</el-button
              >
            </div>
            <div class="but2">
              <el-button
                type="text"
                class="button1"
                icon="el-icon-copy-document"
                >分享</el-button
              >
            </div>
          </div>
        </div></el-tab-pane
      >
      <!-- <el-tab-pane label="新建" name="3" class="tabitem">
      
      </el-tab-pane> -->
    </el-tabs>
    <CalculatePower v-if="!isTrue"></CalculatePower>
  </div>
</template>
  
  <script>
import CalculatePower from "@/components/ElevatorGO/CalculatePower";
export default {
  data() {
    return {
      isTrue: true,
      activeName: "0",
      isIndeterminate: false,
      checkAll: false,
      notFiledList: [
        {
          id: 1,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 2,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 3,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 4,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 5,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 6,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
      ], //未提交列表
      calculateList: [
        {
          id: 1,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 2,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 3,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 4,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 5,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 6,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
      ], //计算中列表
      finishedList: [
        {
          id: 1,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 2,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 3,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 4,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 5,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
        {
          id: 6,
          istrue: false,
          title: "北京市朝阳区万豪酒店项目",
          time: "2021-22-31",
        },
      ], //已完成列表
      checkedCities: [],
    };
  },
  props: {},
  components: { CalculatePower },
  methods: {
    handleCheckedCitiesChange(val) {
      console.log(val);
    },
    handleCheckAllChange(val) {
      console.log(val);
      this.checkAll = !this.checkAll;
    },
    handleClick() {},
    viewInfo(val) {
      console.log(val);
      this.$emit("view");
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>
  
  <style lang="scss" scoped>
:deep .el-tabs__nav {
  width: 100%;
}
:deep .el-tabs__item {
  width: 33%;
  text-align: center;
}
.border-top {
  border-top: 1px dashed #ccc;
}
.item {
  width: 100%;

  display: flex;
  align-items: center;

  padding: 10px;
  .item-checkbox {
    width: 50px;
    text-align: center;
  }
  .text {
    width: calc(100% - 450px);
    height: 100%;
    line-height: 26px;
    .time {
      font-size: 14px;
      color: #ccc;
    }
  }
  .calculate-text {
    width: calc(100% - 200px);
    box-sizing: border-box;
    padding-left: 20px;
    height: 100%;
    line-height: 26px;
    .time {
      font-size: 14px;
      color: #ccc;
    }
  }
  .calculate-finished {
    width: calc(100% - 500px);
    box-sizing: border-box;
    padding-left: 20px;
    height: 100%;
    line-height: 26px;
    .time {
      font-size: 14px;
      color: #ccc;
    }
  }
  .but1 {
    width: 200px;
  }
  .but2 {
    width: 150px;
  }
  .button1 {
    color: #000;
  }
}
.but-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
}
.header {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .title {
    font-weight: bold;
  }
  .header-button {
    margin-left: 50px;
  }
}
</style>