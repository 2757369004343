<template>
  <div style="padding: 0 30px">
    <div class="hint">
      查井道·电梯报告
      <span>提示:“添加电梯”后才能查询!</span> 选择”电梯品牌”可精准查询!
    </div>
    <el-form
      ref="form"
      :model="formInfo"
      label-width="150px"
      size="small"
      style="padding: 10px"
      :rules="rules"
    >
      <el-form-item label="添加业态" prop="i" required>
        <el-radio-group v-model="formInfo.i">
          <el-radio label="0" value="0">酒店</el-radio>
          <el-radio label="1" value="1">办公</el-radio>
          <el-radio label="2" value="2">公寓</el-radio>
          <el-radio label="3" value="3">商业</el-radio>
          <el-radio label="4" value="4">综合体</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="l" v-if="formInfo.i === '4'">
        <div class="content">
          <el-checkbox-group v-model="formInfo.l" class="group">
            <el-checkbox-button label="0" class="item">酒店</el-checkbox-button>
            <el-checkbox-button label="1" class="item">办公</el-checkbox-button>
            <el-checkbox-button label="2" class="item">公寓</el-checkbox-button>
            <el-checkbox-button label="3" class="item">商业</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item label="电梯品牌" prop="a" required>
        <el-radio-group v-model="formInfo.a">
          <el-radio label="0" value="0">全部品牌</el-radio>
          <el-radio label="1" value="1">热门品牌</el-radio>
          <el-radio label="2" value="2">特殊品牌</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="l">
        <div class="content-logo">
          <el-checkbox-group v-model="formInfo.b" class="group">
            <el-checkbox
              v-for="item in logoList"
              :key="item"
              :label="item"
              class="item"
              >{{ item }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
      <div style="text-align: center">
        <el-button type="primary" @click="openElevator">+ 添加电梯</el-button>
      </div>
    </el-form>
    <!-- el-collapse-transition -->
    <div class="content-elevatot" v-if="list.length > 0">
      <div v-for="item in list" :key="item.yt">
        <div v-for="(value, ind) in item.list" :key="value.index + ind">
          <el-row class="item">
            <el-row class="item-title">
              <el-col style="text-align: left" :span="12">
                <span style="color: red; font-weight: bold" v-if="ind == 0"
                  >业态:{{ item.yt }}</span
                >
              </el-col>
              <el-col style="text-align: right" :span="12">
                <el-button icon="el-icon-refresh" type="text">编辑</el-button>
                <el-button icon="el-icon-delete" type="text"
                  >删除电梯</el-button
                >
                <div
                  class="iconbut"
                  @click="changeCollapse(value)"
                  v-if="!value.collapse"
                >
                  <i class="el-icon-arrow-down"></i>
                </div>
                <div
                  class="iconbut"
                  @click="changeCollapse(value)"
                  v-if="value.collapse"
                >
                  <i class="el-icon-arrow-up"></i>
                </div>
              </el-col>
            </el-row>
            <el-collapse-transition>
              <div v-show="value.collapse">
                <el-form label-width="150px" size="small" :model="value">
                  <el-row :gutter="50">
                    <el-col :span="12">
                      <el-form-item label="电梯编号" required>
                        <el-input
                          v-model="value.c"
                          placeholder="输入电梯编号"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12"></el-col>
                  </el-row>
                  <el-row :gutter="50">
                    <el-col :span="12">
                      <el-form-item label="电梯类型" required>
                        {{ value.b }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="载重" required>
                        {{ value.d }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="50">
                    <el-col :span="12">
                      <el-form-item label="梯速" required>
                        {{ value.e }}</el-form-item
                      >
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="行程高度(m)" required
                        ><el-input
                          v-model="value.f"
                          placeholder="输入行程高度"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="50">
                    <el-col :span="12">
                      <el-form-item label="机房类型" required>
                        {{ value.g }}</el-form-item
                      >
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="轿厢类型" required
                        >{{ value.h }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="50">
                    <el-col :span="12">
                      <el-form-item label="开门方式" required
                        >{{ value.i }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="对重类型" required>
                        {{ value.j }}</el-form-item
                      >
                    </el-col>
                  </el-row>
                  <el-row :gutter="50">
                    <el-col :span="12">
                      <el-form-item label="最大停战数" required
                        >{{ value.k }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </el-collapse-transition>

            <div class="bottom-bor"></div>
          </el-row>
        </div>
      </div>
    </div>
    <el-row style="height: 100px; line-height: 100px">
      <el-col :span="12" style="color: #a5a5a5; font-size: 13px">
        小提示:报告生成后，可在“查井道项目列表”查看
      </el-col>

      <el-col :span="12" style="text-align: right">
        <el-button type="primary" @click="submit">GO</el-button>
      </el-col>
    </el-row>
    <FormElevator ref="formElevator" @submit="addElevator"></FormElevator>
  </div>
</template>

<script>
import FormElevator from "./FormElevator";
export default {
  data() {
    return {
      formInfo: {
        b: [],
        l: [],
      },
      logoList: [
        "奥的斯",
        "日本",
        "三菱",
        "通力",
        "迅达",
        "蒂森",
        "鑫宏",
        "科海",
        "唯派",
        "瑞鑫",
        "宏科",
        "宏科1",
        "宏科2",
        "宏科3",
        "宏科4",
      ],
      rules: {},
      list: [],
    };
  },
  props: {},
  components: { FormElevator },
  methods: {
    submit() {},
    changeCollapse(item) {
      console.log(item);
      item.collapse = !item.collapse;
    },
    addElevator(row, isRedact) {
      if (this.isRedact) {
      }
      if (this.list.length > 0) {
        let flag = this.list.find((item) => {
          return item.yt === this.formInfo.i;
        });
        if (flag) {
          this.list.forEach((item) => {
            if (item.yt === this.formInfo.i) {
              item.list.push(row);
            }
          });
        } else {
          this.list.push({ yt: this.formInfo.i, list: [row] });
        }
      } else {
        this.list = [{ yt: this.formInfo.i, list: [row] }];
      }
      console.log(row);
      console.log(this.list);
    },
    openElevator() {
      this.$refs.formElevator.init(false, this.formInfo.i);
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.hint {
  font-weight: bold;
  span {
    color: red;
  }
}
.content {
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  // .group {
  //   .item {
  //     margin-right: 20px;
  //     border-radius: 20px;
  //   }
  // }
}
.content-logo {
  box-sizing: border-box;
  width: 100%;

  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  padding: 20px;
}
.content-elevatot {
  box-sizing: border-box;
  width: 100%;
  border: 10px solid #ccc;
  padding: 20px;
}
.iconbut {
  display: inline-block;
  width: 40px;
  background-color: #ccc;

  text-align: center;
  margin-left: 20px;
  cursor: pointer;
}
.item-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-bor {
  width: 100%;
  border-bottom: 1px dashed #ccc;
}
.item {
  margin-bottom: 20px;
}
</style>