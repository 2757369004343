<template>
  <div>
    <el-row style="margin-bottom: 10px">
      <el-button type="primary" size="mini" @click="back">返回</el-button>
    </el-row>
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="一致性 Consistency" name="1" class="item">
        <template slot="title">
          <div class="item-title">查井道基本项目信息</div>
        </template>
        <el-table :data="tableData" border :show-header="false">
          <el-table-column prop="name" label="ECU信息"> </el-table-column>
          <el-table-column prop="address" label="ECU信息"> </el-table-column>
        </el-table>

        <!-- <el-table :data="loginfo.data" border style="width: 100%">
          <el-table-column prop="name" label="ECU信息"> </el-table-column>
          <template v-for="(col, i) in loginfo.dateList">
            <el-table-column :label="col" :key="col" width="100" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.list[i].id == 0">
                  <a>{{ scope.row.list[i].num }}</a>
                </span>
                <span v-else>
                  {{ scope.row.list[i].config }}
                </span>
              </template>
            </el-table-column>
          </template>
        </el-table> -->
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeNames: "",
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },

        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      loginfo: {
        data: [
          {
            name: "项目名称",
            list: [{ id: 11, value: "北京市朝阳区项目1" }],
          },
          {
            name: "项目城市",
            list: [{ id: 0, num: "北京市大兴区" }],
          },
          {
            name: "业态",
            list: [
              { id: 0, num: "办公" },
              { id: 0, num: "" },
              { id: 0, num: "" },
              { id: 0, num: "" },
            ],
          },
        ],
        dateList: [],
      },
    };
  },
  props: {},
  components: {},
  methods: {
    back() {
      this.$emit("back");
    },
    handleChange() {},
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
:deep .el-collapse-item__header {
  background: #d1100b;
  // text-align: center;
}
:deep .item i {
  color: #fff;
  font-size: 20px;
}
.item-title {
  color: #fff;
  width: 100%;
  text-align: center;
  font-weight: bold;
}
</style>