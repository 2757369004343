<template>
  <div>
    <el-row class="header">
      <div class="title">电梯速查GO</div>
      <el-button type="primary" class="header-button" size="mini" v-if="isTrue" @click="isTrue = false">新建
      </el-button>
      <el-button type="primary" class="header-button" size="mini" v-if="!isTrue" @click="() => {
          (params.name = ''),
            (pageSize = 10),
            (currentPage = 1),
            (isTrue = true),
            getSearch();
        }
        ">返回
      </el-button>
    </el-row>
    <el-row>
      <el-col>
        <div v-if="isTrue" style="display: flex; align-items: center; margin-bottom: 20px">
          <span style="font-weight: bold; font-size: 18px; margin-left: 20px">收藏清单</span>
          <div v-if="isTrue" style="
              width: 300px;
              display: flex;
              align-items: center;
              margin-left: auto;
            ">
            <el-input v-model="params.name" placeholder="请输入名称进行查询" style="height: 30px; flex: 1; margin-right: 10px"
              @keydown.enter.native="getSearch">
            </el-input>
            <el-button size="mini" type="primary" icon="el-icon-search" style="height: 33px; margin-top: 8px"
              @click="getSearch">
              查 询
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="修改收藏清单名称" :visible.sync="centerDialogVisible" width="30%" center>
      <span>请输入您要修改的名称为：</span>
      <el-input v-model="params.Newname" ref="inputdata"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelupdate">取 消</el-button>
        <el-button type="primary" @click="updatename">确 定</el-button>
      </span>
    </el-dialog>
    <div class="content" v-if="isTrue">
      <div v-for="item in Data" :key="item.id">
        <!-- @click.stop="viewInfo" -->
        <div class="border-top"></div>
        <div class="item">
          <div class="item-checkbox"></div>
          <div class="text">
            <div>
              {{ item.RecordName }}
              <el-button type="primary" icon="el-icon-edit" circle size="mini" plain style="margin-left: 10px" @click="() => {
                  (centerDialogVisible = true), params.Newname = '', (rowdata = item), Saveinput();
                }
                "></el-button>
            </div>
            <div class="time">创建时间:{{ item.CreateTime.split(" ")[0] }}</div>
          </div>
          <div class="but1">
            <el-button type="text" class="button1" @click="SetData(item.Id)" icon="el-icon-copy-document">查看
            </el-button>
            <el-button type="text" class="button1" icon="el-icon-star-off" @click="DeleteData(item.Id)">取消收藏
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="Zwuimg" v-if="isTrue && total === 0">
      <svg viewBox="0 0 79 86" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <linearGradient id="linearGradient-1-2" x1="38.8503086%" y1="0%" x2="61.1496914%" y2="100%">
            <stop stop-color="#FCFCFD" offset="0%"></stop>
            <stop stop-color="#EEEFF3" offset="100%"></stop>
          </linearGradient>
          <linearGradient id="linearGradient-2-2" x1="0%" y1="9.5%" x2="100%" y2="90.5%">
            <stop stop-color="#FCFCFD" offset="0%"></stop>
            <stop stop-color="#E9EBEF" offset="100%"></stop>
          </linearGradient>
          <rect id="path-3-2" x="0" y="0" width="17" height="36"></rect>
        </defs>
        <g id="Illustrations" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="B-type" transform="translate(-1268.000000, -535.000000)">
            <g id="Group-2" transform="translate(1268.000000, 535.000000)">
              <path id="Oval-Copy-2"
                d="M39.5,86 C61.3152476,86 79,83.9106622 79,81.3333333 C79,78.7560045 57.3152476,78 35.5,78 C13.6847524,78 0,78.7560045 0,81.3333333 C0,83.9106622 17.6847524,86 39.5,86 Z"
                fill="#F7F8FC"></path>
              <polygon id="Rectangle-Copy-14" fill="#E5E7E9"
                transform="translate(27.500000, 51.500000) scale(1, -1) translate(-27.500000, -51.500000) "
                points="13 58 53 58 42 45 2 45"></polygon>
              <g id="Group-Copy"
                transform="translate(34.500000, 31.500000) scale(-1, 1) rotate(-25.000000) translate(-34.500000, -31.500000) translate(7.000000, 10.000000)">
                <polygon id="Rectangle-Copy-10" fill="#E5E7E9"
                  transform="translate(11.500000, 5.000000) scale(1, -1) translate(-11.500000, -5.000000) "
                  points="2.84078316e-14 3 18 3 23 7 5 7"></polygon>
                <polygon id="Rectangle-Copy-11" fill="#EDEEF2" points="-3.69149156e-15 7 38 7 38 43 -3.69149156e-15 43">
                </polygon>
                <rect id="Rectangle-Copy-12" fill="url(#linearGradient-1-2)"
                  transform="translate(46.500000, 25.000000) scale(-1, 1) translate(-46.500000, -25.000000) " x="38" y="7"
                  width="17" height="36"></rect>
                <polygon id="Rectangle-Copy-13" fill="#F8F9FB"
                  transform="translate(39.500000, 3.500000) scale(-1, 1) translate(-39.500000, -3.500000) "
                  points="24 7 41 7 55 -3.63806207e-12 38 -3.63806207e-12"></polygon>
              </g>
              <rect id="Rectangle-Copy-15" fill="url(#linearGradient-2-2)" x="13" y="45" width="40" height="36"></rect>
              <g id="Rectangle-Copy-17" transform="translate(53.000000, 45.000000)">
                <mask id="mask-4-2" fill="white">
                  <use xlink:href="#path-3-2"></use>
                </mask>
                <use id="Mask" fill="#E0E3E9"
                  transform="translate(8.500000, 18.000000) scale(-1, 1) translate(-8.500000, -18.000000) "
                  xlink:href="#path-3-2"></use>
                <polygon id="Rectangle-Copy" fill="#D5D7DE" mask="url(#mask-4-2)"
                  transform="translate(12.000000, 9.000000) scale(-1, 1) translate(-12.000000, -9.000000) "
                  points="7 0 24 0 20 18 -1.70530257e-13 16"></polygon>
              </g>
              <polygon id="Rectangle-Copy-18" fill="#F8F9FB"
                transform="translate(66.000000, 51.500000) scale(-1, 1) translate(-66.000000, -51.500000) "
                points="62 45 79 45 70 58 53 58"></polygon>
            </g>
          </g>
        </g>
      </svg>
      <span>暂无数据</span>
    </div>
    <div class="block" v-if="isTrue && total !== 0">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="currentPage" :page-size="pageSize" :total="total"
        layout="total, prev, pager, next, sizes, jumper" style="text-align: right">
      </el-pagination>
    </div>
    <Add_ElevatorInquire ref="Add_ElevatorInquire" v-if="!isTrue" />
  </div>
</template>

<script>
import Enumerable from "linq";
import Add_ElevatorInquire from "@/components/ElevatorGO/WellholeInquire/Add_ElevatorInquire";
import ElevatorInquire from "@/components/ElevatorGO/WellholeInquire/ElevatorInquire";
import WellholeInquire from "@/components/ElevatorGO/WellholeInquire/WellholeInquire";

export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 8,
      total: 0,
      rowdata: {},
      Data: [],
      params: { Newname: '' },
      isTrue: true,
      activeName: "1",
      isIndeterminate: false,
      checkAll: false,
      centerDialogVisible: false,
    };
  },
  props: {},
  components: { Add_ElevatorInquire, ElevatorInquire, WellholeInquire },
  methods: {
    // 获取当前登录的用户
    op() {
      return this.$store.state.userInfo;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSearch();
    },
    handleClick() {
    },
    // 查询
    getSearch() {
      this.$http
        .post(
          "/Elevator_Manage/Elevator_Temp_QSRM/GetDataListByMoreCondition",
          {
            PageIndex: this.currentPage,
            PageRows: this.pageSize,
            SortField: "Id",
            SortType: "desc",
            Search: [
              {
                Condition: "CreatorId",
                Keyword: this.op().Id,
              },
              {
                Condition: "RecordName",
                Keyword: this.params.name,
              },
            ],
          }
        )
        .then((res) => {
          if (res.Success) {
            this.Data = res.Data;
            this.total = res.Total;
            setTimeout(function () {
            }, 1000);
            return;
          }
        });
    },
    SetData(id) {
      this.isTrue = false;
      this.$http
        .post("/Elevator_Manage/Elevator_Temp_QSRM/GetTheDataAndDetail", { id })
        .then((res) => {
          if (res.Success) {
            this.$refs["Add_ElevatorInquire"].form = res.Data;
            this.$refs["Add_ElevatorInquire"].newEntity = JSON.parse(JSON.stringify(res.Data));
            res.Data.QSRDDetailsList.forEach((item, index) => {
              item.IndexId = index;
            });
            this.$refs["Add_ElevatorInquire"].tableData =
              res.Data.QSRDDetailsList;
            this.$refs["Add_ElevatorInquire"].newTableData = JSON.parse(
              JSON.stringify(res.Data.QSRDDetailsList)
            ).filter((item) => item.IsShow);
            this.$refs["Add_ElevatorInquire"].operate = false;
            this.$refs["Add_ElevatorInquire"].newTableData.unshift({
              E_Brand: "推荐值",
              E_ShaftWidth: Enumerable.from(
                this.$refs["Add_ElevatorInquire"].newTableData
              ).max((item) => item.E_ShaftWidth),
              E_ShaftDepth: Enumerable.from(
                this.$refs["Add_ElevatorInquire"].newTableData
              ).max((item) => item.E_ShaftDepth),
              E_TopHeight: Enumerable.from(
                this.$refs["Add_ElevatorInquire"].newTableData
              ).max((item) => item.E_TopHeight),
              E_BottomDepth: Enumerable.from(
                this.$refs["Add_ElevatorInquire"].newTableData
              ).max((item) => item.E_BottomDepth),
              // E_LiftCarWidth: Enumerable.from(
              //   this.$refs["Add_ElevatorInquire"].newTableData
              // ).max((item) => item.E_LiftCarWidth),
              // E_LiftCarDepth: Enumerable.from(
              //   this.$refs["Add_ElevatorInquire"].newTableData
              // ).max((item) => item.E_LiftCarDepth),
              // E_LiftCarNetHeight: Enumerable.from(
              //   this.$refs["Add_ElevatorInquire"].newTableData
              // ).max((item) => item.E_LiftCarNetHeight),
              IsShow: 1,
            });
          }
        });
    },
    DeleteData(id) {
      this.$confirm("确定取消收藏当前查询记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Elevator_Manage/Elevator_Temp_QSRM/DeleteData", [id])
            .then((res) => {
              if (res.Success) {
                this.Data = res.Data;
                this.$message.success("操作成功！");
                this.getSearch();
                setTimeout(function () {
                }, 1000);
                return;
              } else {
                this.$message.error(res.Msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    cancelupdate() {
      this.params.Newname = "";
      this.centerDialogVisible = false;
    },
    Saveinput() {
      this.$nextTick(() => {
        console.log(this.$refs["inputdata"]);
        this.$refs.inputdata.focus();
      });
    },
    // 修改清单名称
    updatename() {
      if (!this.params.Newname) {
        this.$message.error("修改名称不能为空~");
        return;
      }
      this.rowdata.RecordName = this.params.Newname;
      this.$http
        .post("/Elevator_Manage/Elevator_Temp_QSRM/UpdateData", this.rowdata)
        .then((res) => {
          if (res.Success) {
            this.Data = res.Data;
            this.$message.success("操作成功！");
            this.params.Newname = "";
            this.centerDialogVisible = false;
            this.getSearch();
            setTimeout(function () {
            }, 1000);
            return;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
  },
  mounted() {
    this.getSearch();
  },
  created() {
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
:deep .el-tabs__nav {
  width: 100%;
}

:deep .tab-top {
  .el-tabs__item {
    width: 33%;
    text-align: center;
  }
}

.Zwuimg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #ccc;
}

.Zwuimg svg {
  width: 30%;
  height: 30%;
}

.border-top {
  border-top: 1px dashed #ccc;
}

.item {
  width: 100%;

  display: flex;
  align-items: center;

  padding: 10px;

  .item-checkbox {
    width: 50px;
    text-align: center;
  }

  .text {
    width: calc(100% - 150px);
    height: 100%;
    line-height: 26px;

    .time {
      font-size: 14px;
      color: #ccc;
    }
  }

  .calculate-text {
    width: calc(100% - 200px);
    box-sizing: border-box;
    padding-left: 20px;
    height: 100%;
    line-height: 26px;

    .time {
      font-size: 14px;
      color: #ccc;
    }
  }

  .calculate-finished {
    width: calc(100% - 200px);
    box-sizing: border-box;
    padding-left: 20px;
    height: 100%;
    line-height: 26px;

    .time {
      font-size: 14px;
      color: #ccc;
    }
  }

  .but1 {
    width: 200px;
  }

  .button1 {
    color: #000;
  }
}

.but-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.header {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .header-button {
    margin-left: 30px;
  }
}
</style>
