<template>
  <div class="wrap">
    <el-form ref="form" :model="form" label-width="150px" size="small" :rules="rules">
      <el-collapse v-model="activeNames" @change="handleChange" style="width: 100%">
        <el-collapse-item name="0">
          <template slot="title">
            <div class="custom-title">
              <span>必选条件</span>
            </div>
          </template>
          <el-form-item label="载重(kg)：" prop="E_Load">
            <el-radio-group v-model="form.E_Load" size="small" @change="ValidateLiftCarArea">
              <el-radio-button v-for="(item, index) in Data.E_Load" :key="index" :label="item.DicName" :value="item.DicKey"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="梯速(m/s)：" prop="E_Speed">
            <el-radio-group v-model="form.E_Speed" size="small">
              <el-radio-button v-for="(item, index) in Data.E_Speed" :key="index" :label="item.DicName" :value="item.DicKey"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="机房类型：" prop="E_RoomType">
            <el-radio-group v-model="form.E_RoomType" size="small">
              <el-radio-button v-for="(item, index) in Data.E_RoomType" :key="index" :label="item.DicName" :value="item.DicKey"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="轿厢类型：" prop="E_LiftCarType">
            <el-radio-group v-model="form.E_LiftCarType" size="small">
              <el-radio-button v-for="(item, index) in Data.E_LiftCarType" :key="index" :label="item.DicName" :value="item.DicKey"></el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-collapse-item>
      </el-collapse>
      <el-collapse v-model="activeNames" @change="handleChange" style="margin-bottom: 30px; width: 100%">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="custom-title">
              <span>选填条件</span><span style="font-size: 12px;margin-left: 5px;color: rgba(0,0,0,0.3)">(注：轿厢面积校验基于轿厢宽度和轿厢深度均填写后校验，默认不填写为：校验成功)</span>
              <el-button size="mini" style="margin-left: 20px;z-index: 999999;" type="text" @click.stop="resetMSG">重置</el-button>
            </div>
          </template>
          <el-row>
            <el-col :span="6">
              <el-form-item label="对重类型：" prop="E_CounterWeight">
                <el-radio-group v-model="form.E_CounterWeight" size="small">
                  <el-radio-button v-for="(item, index) in Data.E_CounterWeight" :key="index" :label="item.DicName" :value="item.DicKey" @click.native="handleRadioTrigger($event,'E_CounterWeight')"></el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="开门方式：" prop="E_OpenDoorMode">
                <el-radio-group v-model="form.E_OpenDoorMode" size="small">
                  <el-radio-button v-for="(item, index) in Data.E_OpenDoorMode" :key="index" :label="item.DicName" :value="item.DicKey" @click.native="handleRadioTrigger($event,'E_OpenDoorMode')"></el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提升高度：" prop="E_MaxLiftHeight">
                <el-input oninput="value=value=value.replace(/[^\d.]/g,'')" v-model="form.E_MaxLiftHeight"><span slot="suffix">m</span></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="轿厢净高：" prop="E_LiftCarNetHeight">
                <el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="form.E_LiftCarNetHeight"><span slot="suffix">mm</span></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="轿厢宽度：">
                <el-input oninput="value=value.replace(/[^\d]/g,'')" @input="ValidateLiftCarArea" v-model="form.E_LiftCarWidth"><span
                    slot="suffix">mm</span></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="轿厢深度：">
                <el-input oninput="value=value.replace(/[^\d]/g,'')" @input="ValidateLiftCarArea" v-model="form.E_LiftCarDepth"><span
                    slot="suffix">mm</span></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="轿厢面积校验：">
                <i v-if="success" class="el-icon-circle-check" style="color: green;font-weight: bold"><span>校验成功</span></i>
                <i v-else class="el-icon-circle-close" style="color: red;font-weight: bold"><span>校验失败</span></i>
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <el-row type="flex" justify="space-between" style="margin-bottom: 10px;">
      <div>
        <el-button type="primary" :disabled="tableData.length <= 0" @click="collect" v-if="this.operate">加入收藏项目</el-button>
        <el-button type="primary" :disabled="tableData.length <= 0" @click="collect" v-if="!this.operate">保存</el-button>
        <el-button type="primary" :disabled="tableData.length <= 0" @click="reset">重置计算列表</el-button>
      </div>
      <el-button type="primary" @click="query">查询</el-button>
    </el-row>
    <el-table :cell-class-name="cellStyle" :data="newTableData" style="width: 100%"
              :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
      <el-table-column prop="E_Brand" label="厂家名称" min-width="100"></el-table-column>
      <el-table-column prop="E_Series" label="产品系列" min-width="180"></el-table-column>
      <el-table-column label="井道尺寸">
        <el-table-column prop="E_ShaftWidth" label="宽度(mm)" min-width="80"></el-table-column>
        <el-table-column prop="E_ShaftDepth" label="深度(mm)" min-width="80"></el-table-column>
      </el-table-column>
      <el-table-column prop="E_TopHeight" label="顶层高度(mm)" min-width="100"></el-table-column>
      <el-table-column prop="E_BottomDepth" label="底坑深度(mm)" min-width="100"></el-table-column>
      <el-table-column prop="E_CounterWeight" label="对重类型" min-width="80"></el-table-column>
      <el-table-column prop="E_OpenDoorMode" label="开门方式" min-width="80"></el-table-column>
      <el-table-column label="轿厢尺寸">
        <el-table-column prop="E_LiftCarWidth" label="宽度(mm)" min-width="80"></el-table-column>
        <el-table-column prop="E_LiftCarDepth" label="深度(mm)" min-width="80"></el-table-column>
        <el-table-column prop="E_LiftCarNetHeight" label="净高(mm)" min-width="80"></el-table-column>
      </el-table-column>
      <el-table-column prop="E_MaxLiftHeight" label="最大提升高度(m)" min-width="110"></el-table-column>
      <el-table-column label="操作" min-width="80">
        <template slot-scope="scope">
          <el-button v-if="!scope.$index == 0" @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">移出计算</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Enumerable from 'linq';

export default {
  data() {
    return {
      activeNames: ["0"],
      Data: [],
      formInfo: {},
      form: {E_CounterWeight: '', E_MaxLiftHeight: "", E_LiftCarNetHeight: '', E_OpenDoorMode: '', E_LiftCarWidth: "", E_LiftCarDepth: "", E_LiftCarDoorH: '', E_LiftCarDoorW: ''},
      rules: {
        E_Load: [
          {required: true, message: '请选择载重', trigger: 'change'}
        ],
        E_Speed: [
          {required: true, message: '请选择梯速', trigger: 'change'}
        ],
        E_RoomType: [
          {required: true, message: '请选择机房类型', trigger: 'change'}
        ],
        E_LiftCarType: [
          {required: true, message: '请选择轿厢类型', trigger: 'change'}
        ],
      },
      list: [],
      tableData: [],
      newTableData: [],
      success: true,
      operate: true,
      newEntity: {},
    };
  },
  mounted() {
    this.getDatalist();
  },

  props: {},
  components: {},

  methods: {
    handleChange(val) {
      console.log(val);
    },
    resetMSG() {
      this.form.E_CounterWeight = ''
      this.form.E_MaxLiftHeight = ''
      this.form.E_LiftCarNetHeight = ''
      this.form.E_OpenDoorMode = ''
      this.form.E_LiftCarWidth = ''
      this.form.E_LiftCarDepth = ''
      this.form.E_LiftCarDoorH = ''
      this.form.E_LiftCarDoorW = ''
      this.success = true;
    },
    //查询条件是否变更判断
    validateQueryCondition() {
      console.log(this.newEntity)
      const errorMessage = [];
      if ((this.form.E_Load ? this.form.E_Load.toString() : '') !== (this.newEntity.E_Load ? this.newEntity.E_Load.toString() : '')) {
        errorMessage.push('载重')
      }
      if ((this.form.E_Speed ? this.form.E_Speed.toString() : '') !== (this.newEntity.E_Speed ? this.newEntity.E_Speed.toString() : '')) {
        errorMessage.push('梯速')
      }
      if ((this.form.E_RoomType ? this.form.E_RoomType.toString() : '') !== (this.newEntity.E_RoomType ? this.newEntity.E_RoomType.toString() : '')) {
        errorMessage.push('机房类型')
      }
      if ((this.form.E_LiftCarType ? this.form.E_LiftCarType.toString() : '') !== (this.newEntity.E_LiftCarType ? this.newEntity.E_LiftCarType.toString() : '')) {
        errorMessage.push('轿厢类型')
      }
      if ((this.form.E_CounterWeight ? this.form.E_CounterWeight.toString() : '') !== (this.newEntity.E_CounterWeight ? this.newEntity.E_CounterWeight.toString() : '')) {
        errorMessage.push('对重类型')
      }
      if ((this.form.E_OpenDoorMode ? this.form.E_OpenDoorMode.toString() : '') !== (this.newEntity.E_OpenDoorMode ? this.newEntity.E_OpenDoorMode.toString() : '')) {
        errorMessage.push('开门方式')
      }
      if ((this.form.E_MaxLiftHeight ? this.form.E_MaxLiftHeight.toString() : '') !== (this.newEntity.E_MaxLiftHeight ? this.newEntity.E_MaxLiftHeight.toString() : '')) {
        errorMessage.push('提升高度')
      }
      if ((this.form.E_LiftCarNetHeight ? this.form.E_LiftCarNetHeight.toString() : '') !== (this.newEntity.E_LiftCarNetHeight ? this.newEntity.E_LiftCarNetHeight.toString() : '')) {
        errorMessage.push('轿厢净高')
      }
      if ((this.form.E_LiftCarWidth ? this.form.E_LiftCarWidth.toString() : '') !== (this.newEntity.E_LiftCarWidth ? this.newEntity.E_LiftCarWidth.toString() : '')) {
        errorMessage.push('轿厢宽度')
      }
      if ((this.form.E_LiftCarDepth ? this.form.E_LiftCarDepth.toString() : '') !== (this.newEntity.E_LiftCarDepth ? this.newEntity.E_LiftCarDepth.toString() : '')) {
        errorMessage.push('轿厢深度')
      }
      if (errorMessage.length > 0) {
        this.$message.error(`[${errorMessage.join('、')}]发生变化，无法保存，请先点击查询!`)
        return true;
      }
    },
    //加入收藏
    collect() {
      if (this.validateQueryCondition()) {
        return;
      }
      this.$http.post('/Elevator_Manage/Elevator_Temp_QSRM/SaveData', {...this.form, QSRDDetailsList: this.tableData}).then(res => {
        if (res.Success) {
          this.$message.success('操作成功！')
          this.$parent.isTrue = true;
          this.$parent.getSearch();
        }
      }).catch(err => {
        this.$message.error(err);
      })
    },
    cellStyle({row, column, rowIndex, columnIndex}) {
      if (row.ECW_Prominent != 0 && columnIndex == 6 && rowIndex != 0) {
        return "ECW_Prominent-row";
      }
      if (row.EOM_Prominent != 0 && columnIndex == 7 && rowIndex != 0) {
        return "EOM_Prominent-row";
      }
      return "";
    },
    //重置计算
    reset() {
      this.tableData.forEach(item => {
        item.IsShow = 1;
      })
      this.newTableData = JSON.parse(JSON.stringify(this.tableData));
      this.newTableData.unshift(
          {
            "E_Brand": "推荐值",
            "E_ShaftWidth": Enumerable.from(this.newTableData).max(item => item.E_ShaftWidth),
            "E_ShaftDepth": Enumerable.from(this.newTableData).max(item => item.E_ShaftDepth),
            "E_TopHeight": Enumerable.from(this.newTableData).max(item => item.E_TopHeight),
            "E_BottomDepth": Enumerable.from(this.newTableData).max(item => item.E_BottomDepth),
            // "E_LiftCarWidth": Enumerable.from(this.newTableData).max(item => item.E_LiftCarWidth),
            // "E_LiftCarDepth": Enumerable.from(this.newTableData).max(item => item.E_LiftCarDepth),
            // "E_LiftCarNetHeight": Enumerable.from(this.newTableData).max(item => item.E_LiftCarNetHeight),
            "IsShow": 1
          }
      );
    },
    query() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.success) {
            this.$http.post('/Elevator_Manage/Elevator_SampleInfo/GetElevatorSampleInfoSuggestValue', this.form).then(res => {
              this.newEntity = JSON.parse(JSON.stringify(this.form));
              this.tableData = res.Data;
              this.tableData.forEach((item, index) => {
                item.IndexId = new Date().getTime() + index;
              })
              let flag = this.tableData.some(item => item.ErrorLiftHeight == 1);
              let flag1 = this.tableData.some(item => item.ECW_Prominent == 1);//对重
              let flag2 = this.tableData.some(item => item.EOM_Prominent == 1);//开门
              if (this.tableData.length > 0 && (flag || flag1 || flag2)) {
                const h = this.$createElement;
                this.$msgbox({
                  title: '温馨提示',
                  message: h('p', null, [
                    h('p', null, flag ? '提升高度：' : ''),
                    h('p', {style: 'margin: 0 0 20px 0'}, flag ? '当前输入提升高度高于产品要求，请尝试增加梯速' : ''),
                    h('p', null, flag1 ? '对重类型：' : ''),
                    h('p', {style: 'margin: 0 0 20px 0'}, flag1 ? '如果存在不同对重类型，可移除该行数据，不参与本次对比' : ""),
                    h('p', null, flag2 ? '开门方式：' : ''),
                    h('p', null, flag2 ? '如果存在不同开门方式，可移除该行数据，不参与本次对比' : ''),
                  ]),
                  showCancelButton: false,
                  confirmButtonText: '确定',
                  cancelButtonText: '',
                })
              }
              this.newTableData = JSON.parse(JSON.stringify(res.Data));
              this.newTableData.unshift(
                  {
                    "E_Brand": "推荐值",
                    "E_ShaftWidth": Enumerable.from(this.newTableData).max(item => item.E_ShaftWidth),
                    "E_ShaftDepth": Enumerable.from(this.newTableData).max(item => item.E_ShaftDepth),
                    "E_TopHeight": Enumerable.from(this.newTableData).max(item => item.E_TopHeight),
                    "E_BottomDepth": Enumerable.from(this.newTableData).max(item => item.E_BottomDepth),
                    // "E_LiftCarWidth": Enumerable.from(this.newTableData).max(item => item.E_LiftCarWidth),
                    // "E_LiftCarDepth": Enumerable.from(this.newTableData).max(item => item.E_LiftCarDepth),
                    // "E_LiftCarNetHeight": Enumerable.from(this.newTableData).max(item => item.E_LiftCarNetHeight),
                    "IsShow": 1
                  }
              );
            }).catch(err => {
              // this.$message.error(err);
            })
          } else {
            this.$message.error('轿厢面积校验错误，请调整轿厢宽/轿厢深')
          }
        } else {
          return false;
        }
      });

    },
    deleteRow(index, rows) {
      this.tableData.forEach(item => {
        if (item.IndexId == this.newTableData[index].IndexId) {
          item.IsShow = 0
        }
      })
      this.newTableData.splice(index, 1)
      let setData = this.newTableData.slice(1)
      if (setData.length <= 0) {
        this.newTableData.length = 0;
        return;
      }
      this.newTableData.splice(0, 1,
          {
            "E_Brand": "推荐值",
            "E_ShaftWidth": Enumerable.from(setData).max(item => item.E_ShaftWidth),
            "E_ShaftDepth": Enumerable.from(setData).max(item => item.E_ShaftDepth),
            "E_TopHeight": Enumerable.from(setData).max(item => item.E_TopHeight),
            "E_BottomDepth": Enumerable.from(setData).max(item => item.E_BottomDepth),
            // "E_LiftCarWidth": Enumerable.from(setData).max(item => item.E_LiftCarWidth),
            // "E_LiftCarDepth": Enumerable.from(setData).max(item => item.E_LiftCarDepth),
            // "E_LiftCarNetHeight": Enumerable.from(setData).max(item => item.E_LiftCarNetHeight),
            "IsShow": 1
          }
      );
    },
    getDatalist() {
      this.$http
          .post(
              "/Base_ProjectManage/Project_DictionaryMain/GetDataDetailByDicKeyList",
              [
                "E_Load",
                "E_Speed",
                "E_RoomType",
                "E_LiftCarType",
                "E_CounterWeight",
                "E_OpenDoorMode",
                "E_MaxStop",
              ]
          )
          .then((res) => {
            if (res.Success) {
              this.Data = res.Data;
              for (let key in this.Data) {
                this.Data[key] = JSON.parse(this.Data[key]);
              }
              console.log(this.Data);
            }
          });
    },
    //轿箱校验
    ValidateLiftCarArea() {
      if (this.form.E_LiftCarWidth === '' || this.form.E_LiftCarDepth === '') {
        this.success = true;
        return;
      }
      if (this.form.E_Load && this.form.E_LiftCarWidth && this.form.E_LiftCarDepth) {
        let obj = {
          E_Load: this.form.E_Load,
          E_LiftCarWidth: this.form.E_LiftCarWidth,
          E_LiftCarDepth: this.form.E_LiftCarDepth,
        }
        this.$http.post('/Elevator_Manage/Elevator_LiftCarArea/ValidateLiftCarArea', obj).then(res => {
          this.success = res.Data;
        }).catch(err => {
          this.$message.error(err);
        })
      }
    },
    //条件选择
    setCondition(label, DicName) {
      if (!this.operate) {
        return;
      }
      switch (label) {
        case '载重':
          this.$set(this.form, 'E_Load', DicName)
          this.ValidateLiftCarArea();
          break;
        case '梯速':
          this.$set(this.form, 'E_Speed', DicName)
          break;
        case '机房类型':
          this.$set(this.form, 'E_RoomType', DicName)
          break;
        case '轿厢类型':
          this.$set(this.form, 'E_LiftCarType', DicName)
          break;
        case '对重类型':
          this.$set(this.form, 'E_CounterWeight', DicName)
          break;
        case '开门方式':
          this.$set(this.form, 'E_OpenDoorMode', DicName)
          break;
      }
    },
    // 动态取消单选框的值 并且根据传入的属性名称 找到对应的单选组件
    handleRadioTrigger($event, prop) {
      let _this = this;
      let objVal = this.form[`${prop}`];
      window.setTimeout(() => {
        if (!!objVal && objVal == $event.target.value) {
          $event.target.checked = false
          _this.form[`${prop}`] = ''
        }
      }, 0);
    }
  },
  created() {
  },
  watch: {},
};
</script>

<style scoped lang="scss">

/* 去掉外层容器滚动 */
.wrap {
  height: calc(100vh - 112px); //固定高度
  overflow: auto; //设置滚动
  scrollbar-color: transparent transparent; //兼容火狐
  &::-webkit-scrollbar {
    display: none; //隐藏滚动条
  }
}

/* 条件单选框 被禁用时的样式 */
::v-deep .el-radio-button__orig-radio:disabled:checked + .el-radio-button__inner {
  color: #FFFFFF !important;
  cursor: not-allowed !important;
  background-image: none !important;
  background-color: #D1100B !important;
  border-color: #D1100B !important;
  box-shadow: none !important;
}

/* // 去掉eltable的hover效果 */
::v-deep .el-table tbody tr:hover > td {
  background-color: #ccc !important;
}

::v-deep .ECW_Prominent-row {
  background-color: #CE0000 !important;
  color: #fff;
  font-weight: 600;
}

::v-deep .EOM_Prominent-row {
  background-color: #0072E3 !important;
  color: #fff;
  z-index: 9999999999;
  font-weight: 600;
}

.hint {
  font-weight: bold;

  span {
    color: red;
  }
}

.data {
  display: inline-block;
  padding-left: 5px;
}

::v-deep .el-form-item--small .el-form-item__label {
  font-weight: bold;
}

::v-deep.el-table tbody tr:hover > td {
  background-color: transparent;
}

.custom-title {
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
}

.content {
  width: 100%;
  height: 50px;
  border: 1px solid #ccc;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-logo {
  box-sizing: border-box;
  width: 100%;

  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.item {
  margin-bottom: 20px;
}

.list {
  cursor: pointer;
}

::v-deep .el-radio-button__inner {
  font-weight: bold !important;
}

/* 修改第一行推荐值样式 */
::v-deep tbody tr:first-child {
  color: #fff;
  background: #EA7500;
  font-weight: 600;
}

/* 修改表格样式 */
::v-deep .el-table .el-table__cell {
  padding: 3px 0;
}

/* 修改表头样式 */
::v-deep .el-table thead.is-group th.el-table__cell {
  color: #fff;
  background: #c02d20;
  padding: 5px 0;
}

/* 修改第一行推荐值鼠标悬浮时颜色不变 */
::v-deep .el-table__body tr:first-child:hover td {
  background: #EA7500 !important;
}

</style>
